import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home";
import AuthorizedLayout from "@/layouts/AuthorizedLayout";
import Login from "../views/Login";
import NotAuthorizedLayout from "../layouts/NotAuthorizedLayout";
import PageNotFound from "@/views/PageNotFound";
import Statistics from "@/views/Statistics.vue";
import Monitoring from "@/views/Monitoring.vue";
import Widget from "@/views/Widget.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: NotAuthorizedLayout,
    children: [
      {
        path: "",
        name: "login",
        component: Login,
      },
    ],
  },
  {
    path: "/",
    component: AuthorizedLayout,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "statistics",
        name: "statistics",
        component: Statistics,
      },
      {
        path: "monitoring",
        name: "monitoring",
        component: Monitoring,
      },
      {
        path: "widget",
        name: "widget",
        component: Widget,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const api_key = localStorage.getItem("api_key");
  if (!api_key) {
    if (to.name !== "login") next({ name: "login" });
  } else if (to.name === "login") {
    console.log("TO LOGIN");
    next({ name: "home" });
  }
  next();
});

export default router;
