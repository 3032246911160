import { instance } from "@/plugins/axios";

export const getCsrf = async () => {
  document.cookie = "";
  try {
    const response = await instance.get("/auth/csrft/");
    document.cookie = `csrftoken=${response.data.scrft}`;
  } catch (e) {
    return null;
  }
};
