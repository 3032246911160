<template>
  <div class="app-bar">
    <div class="user">
      <img
        style="margin-right: 15px"
        class="app-bar-icon"
        src="@/assets/svg/user-icon.svg"
        alt="user icon"
      />
      <div class="user-inner">
        <p class="user-login">
          {{ profile.first_name }} {{ profile.last_name }}
        </p>
      </div>
    </div>
    <v-btn icon @click="logout">
      <img
        class="app-bar-icon"
        src="@/assets/svg/logout-icon.svg"
        alt="logout button"
      />
    </v-btn>
  </div>
</template>

<script>
import { getProfile } from "@/http/profile";
import { logoutApi } from "@/http/logout";
import { getCurrentMonth } from "@/http/statistics";
export default {
  name: "AppBar",
  data() {
    return {
      profile: "",
    };
  },
  methods: {
    async getProfile() {
      try {
        await getCurrentMonth();
        const response = await getProfile();
        this.profile = response.data;
        this.$store.commit("saveUserProfile", response.data);
      } catch (e) {
        this.$root.$emit(
          "showCustomError",
          "Ошибка во время получении информации по профилю"
        );
      }
    },

    async logout() {
      await logoutApi();
      this.$store.commit("removeApiKey");
      await this.$router.push({ name: "login" });
    },
  },
  created() {
    this.getProfile();
  },
};
</script>

<style scoped lang="scss">
.app-bar {
  margin-bottom: 37px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 60px;
  background-color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .app-bar-icon {
    height: 20px;
    width: 20px;
  }

  .title {
    font-family: "Gilroy-Light", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0b3954;
  }

  .user {
    margin-right: 40px;
    height: 39px;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: "Gilroy-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;

    .user-login {
      font-size: 18px;
      line-height: 21px;
      color: #0b3954;
    }
    .user-role {
      font-size: 15px;
      line-height: 18px;
      color: #d2d1cd;
    }
  }
}
</style>
