<template>
  <router-view />
</template>

<script>
import { getCsrf } from "@/http/csrf";

export default {
  name: "App",
  async beforeCreate() {
    document.title = "Welcome Biometric | Admin";
    await getCsrf();
  },
};
</script>

<style lang="scss">
.v-app {
  min-height: 100vh;
}
.v-data-table {
  margin-top: 20px;
  border-radius: 10px !important;
  overflow: hidden;
}
.v-data-table .v-data-table__wrapper table tbody tr:nth-child(2n - 2) {
  background-color: #f9f9f9 !important;
}
.v-data-table .v-data-table__wrapper table tbody tr:nth-child(2n - 1) {
  background-color: #ffffff !important;
}
.v-data-table .v-data-table__wrapper table thead tr {
  background-color: #e7f0fa !important;
}
.header_text {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
}
.title_color {
  color: #3474d3;
}
p {
  margin: 0 !important;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    margin-bottom: 5px;
    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #2c2c32;
    letter-spacing: 0.1px;
  }
  input {
    height: 46px !important;
    min-height: 24px !important;
    max-height: 46px !important;
    padding: 11px 10px !important;
    background: #f9f9f9;
    border: 1px solid #d7dfe8;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #2c2c32;
  }
  .input {
    width: 100%;
    height: 43px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #3474d3;

    border: 1px solid #3474d3;
    box-sizing: border-box;
    border-radius: 8px;
  }
  input[type="file"] {
    display: none;
  }
}

.input-wrapper-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  label {
    margin-bottom: 25px;
    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #2c2c32;
    letter-spacing: 0.1px;
  }
  .input {
    width: 100%;
    height: 43px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #3474d3;

    border: 1px solid #3474d3;
    box-sizing: border-box;
    border-radius: 8px;
  }
  input[type="file"] {
    display: none;
  }
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    margin-bottom: 5px;
    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #2c2c32;
    letter-spacing: 0.1px;
  }

  input {
    height: 46px !important;
    min-height: 46px !important;
    min-width: 200px !important;
    padding: 11px 10px !important;
    background: #f9f9f9;
    border: 1px solid #d7dfe8;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #2c2c32;
  }
}

.save-btn {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  height: 44px !important;

  font-family: Gilroy-Bold, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff !important;

  background: #3474d3 !important;
  box-shadow: 0 4px 20px rgba(62, 61, 87, 0.2);
  border-radius: 8px;
}

.save-btn:disabled {
  background: #d6dfea;
  border-radius: 8px;
}

.helper-container {
  margin-bottom: 30px;
  padding: 10px 40px 10px 10px;
  width: 100%;
  height: 56px;
  background: #e7f0fa;
  border-radius: 8px;
  border-left: 10px solid #3474d3;

  .helper-text {
    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #0b3954;
  }
}

.input-wrapper .v-input__slot fieldset,
.input-wrapper-row .v-input__slot fieldset {
  border: none !important;
}
.input-wrapper .v-input__control .v-input__slot,
.input-wrapper-row .v-input__control .v-input__slot {
  height: 46px !important;
  padding: 0 !important;
}
.v-select__selections {
  height: 46px !important;
  min-height: 46px !important;
  padding: 11px 10px !important;
  background: #f9f9f9;
  border: 1px solid #d7dfe8;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #2c2c32;

  input {
    height: 24px !important;
    padding: 0 !important;
    border: none;
  }
}
.v-select__selection--comma {
  margin: 0 !important;
}
.v-snack__content {
  padding: 0 !important;
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.v-tabs {
  background: inherit !important;
}
.theme--light.v-tabs-items {
  background-color: inherit !important;
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: inherit !important;
}
</style>
