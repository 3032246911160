import Vue from "vue";
import Vuex from "vuex";
import { setApiKey, unsetApiKey } from "@/plugins/axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api_key: localStorage.getItem("api_key"),
    user: {
      isAuthenticated: false,
      email: "",
      first_name: "",
      last_name: "",
      is_verified: false,
      phone: "",
      status: null,
    },
    organization_id: "",
  },
  mutations: {
    saveOrganizationId(state, payload) {
      state.organization_id = payload;
    },
    saveApiKey(state, payload) {
      state.api_key = payload;
      state.user.isAuthenticated = true;
      localStorage.setItem("api_key", payload);
      setApiKey(state.api_key);
    },
    removeApiKey(state) {
      state.api_key = null;
      localStorage.clear();
      unsetApiKey();
    },
    saveUserProfile(state, payload) {
      state.user.first_name = payload.first_name;
      state.user.last_name = payload.last_name;
      state.user.email = payload.email;
      state.user.phone = payload.phone;
      state.user.status = payload.status;
      state.user.is_verified = payload.is_verified;
    },
  },
  actions: {
    saveApiKey({ commit }, payload) {
      commit("saveApiKey", payload);
    },
    removeApiKey({ commit }) {
      commit("removeApiKey");
    },
  },
  getters: {},
  modules: {},
});
