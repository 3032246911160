<template>
  <v-dialog v-model="this.dialog" width="800" persistent>
    <v-card class="rounded-lg pa-2" style="overflow-x: hidden">
      <v-card-title class="pb-5">
        <v-row align="center" justify="space-between">
          <v-col class="px-0" cols="auto"></v-col>
          <v-col class="px-0" cols="auto">
            <p class="dialog-title">Сессия</p>
            <p>{{ this.id }}</p>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-btn icon @click="closeDialog">
              <v-icon size="30" color="error">mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 15px 40px">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="session"
          :loading="loading"
          loading-text="Пожалуйста подождите, данные загружаются..."
          hide-default-footer
        >
          <template v-slot:item.photo="{ item }">
            <img
              class="pa-5 image"
              v-if="item.photo"
              :src="item.photo"
              alt="Фотография лайвнесс"
            />
            <p v-else>Фотография не найдена</p>
          </template>
          <template v-slot:item.face_photo="{ item }">
            <img
              class="pa-5 image"
              v-if="item.face_photo"
              :src="item.face_photo"
              alt="Фотография с документа"
            />
            <p v-else>Фотография не найдена</p>
          </template>
          <template v-slot:item.result="{ item }">
            <span :class="item.result === false ? 'red--text' : 'green--text'">
              {{ item.result ? "Пройдено" : "Не пройдено" }}
            </span>
          </template>
          <template v-slot:item.date="{ item }">
            {{ item.date.replace("T", "") }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getTransactionBySession } from "@/http/transactions";

export default {
  name: "SessionInfo",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      loading: true,
      session: [],
      menuFrom: false,
      menuTo: false,
      id: null,
      headers: [
        {
          text: "Сессия",
          align: "center",
          sortable: false,
          value: "main_session_id",
        },
        {
          text: "Результат",
          align: "center",
          sortable: false,
          value: "result",
        },
        {
          text: "Технологии",
          align: "center",
          sortable: false,
          value: "technologies",
        },
        {
          text: "Дата прохождения",
          align: "center",
          sortable: false,
          value: "date",
        },
        {
          text: "Фотография с Лайвнесс",
          align: "center",
          sortable: false,
          value: "photo",
        },
        {
          text: "Фотография с документа",
          align: "center",
          sortable: false,
          value: "face_photo",
        },
      ],
    };
  },
  methods: {
    getUrl(mediaLink) {
      const url = process.env.VUE_APP_BACKEND_URL.replace("/v1", "");
      return `${url}${mediaLink}`;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    async getSessionInfo(id) {
      this.id = id;
      this.loading = true;
      this.session = [];
      try {
        const response = await getTransactionBySession(this.id);
        const technologies = [];

        if (response.data.document_verification_session)
          technologies.push("Document");
        if (response.data.liveness_session) technologies.push("Liveness");
        if (response.data.face_session) technologies.push("F2F");

        const temp = {
          main_session_id: this.id,
          technologies: technologies,
          result:
            response.data.liveness_session?.result ||
            !!response.data.document_verification_session ||
            response.data.face_session?.result ||
            false,
          date: "123",
          photo:
            response.data.liveness_session?.photo ||
            response.data.face_session?.comparison_photo ||
            "",
          face_photo:
            response.data.document_verification_session?.images?.face_photo ||
            response.data.face_session?.face_photo ||
            "",
        };

        if (temp.photo) {
          temp.photo = `data:image/png;base64, ${temp.photo}`;
        }
        if (temp.face_photo) {
          temp.face_photo = `data:image/png;base64, ${temp.face_photo}`;
        }

        this.session.push(temp);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.create-item {
  width: 240px;
  height: 41px;

  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-transform: none !important;
}

.dialog-title {
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #3474d3;
}

.preview {
  position: relative;
  height: 125px;
  width: 125px;

  .delete-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.image {
  width: 250px;
}
</style>
