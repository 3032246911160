<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center" justify="center">
      <v-col cols="auto" class="pl-7">
        <p class="title">Настройка виджета организации</p>
      </v-col>
    </v-row>
    <v-row class="options" align="center" justify="center">
      <div class="switch">
        <div class="switch__row">
          <div class="switch__row__label">Включить/Выключить виджет</div>
          <v-switch v-model="is_active" color="primary" hide-details />
        </div>
        <div class="switch__row">
          <div class="switch__row__label">
            Включить/Выключить кастомное лого
          </div>
          <v-switch v-model="custom_widget_logo" color="primary" hide-details />
        </div>
        <div class="switch__row">
          <div class="switch__row__label">
            Количество сторон для верификации документа
          </div>
          <v-radio-group v-model="document_verification_side" row hide-details>
            <v-radio label="1" :value="1"></v-radio>
            <v-radio label="2" :value="2"></v-radio>
          </v-radio-group>
        </div>
        <div class="switch__row">
          <div class="switch__row__label">Запись видео прхождения Liveness</div>
          <v-switch v-model="video_recording" color="primary" hide-details />
        </div>
        <div class="switch__row">
          <div class="switch__row__label">Показывать полный результат</div>
          <v-switch v-model="show_full_result" color="primary" hide-details />
        </div>
        <div class="switch__row switch__button">
          <v-btn
            :loading="button.loading"
            :disabled="button.disabled"
            color="success"
            class="mr-4"
            @click="updateWidget"
          >
            Отправить
          </v-btn>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { getWidget, updateWidget } from "@/http/widget";
export default {
  name: "Widget",
  data() {
    return {
      is_active: false,
      custom_widget_logo: false,
      document_verification_side: null,
      video_recording: false,
      show_full_result: false,
      button: {
        loading: false,
        disabled: false,
      },
    };
  },
  methods: {
    async setWidgetValues() {
      const widget = await getWidget();
      if (widget) {
        this.is_active = widget.data.is_active;
        this.custom_widget_logo = widget.data.custom_widget_logo;
        this.document_verification_side =
          +widget.data.document_verification_side;
        this.video_recording = widget.data.video_recording;
        this.show_full_result = widget.data.show_full_result;
      } else {
        this.$root.$emit(
          "showCustomError",
          "Не удалось получить данные по виджету"
        );
      }
    },
    async updateWidget() {
      console.log(this.document_verification_side);
      this.button.loading = true;
      this.button.disabled = true;
      const response = await updateWidget(
        this.is_active,
        this.custom_widget_logo,
        this.document_verification_side,
        this.video_recording,
        this.show_full_result
      );
      this.button.loading = false;
      this.button.disabled = false;
      if (response) this.$root.$emit("showCustomSuccess", "Данные обновлены");
      else this.$root.$emit("showCustomError", "Не удалось изменить данные");
    },
  },

  async mounted() {
    await this.setWidgetValues();
  },
};
</script>

<style scoped>
.switch {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.switch__row {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
}
.switch__button {
  justify-content: center;
}
</style>
