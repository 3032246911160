import { instance } from "@/plugins/axios";

export const loginApi = async (email, password) => {
  const payload = {
    email,
    password,
  };
  try {
    return await instance.post("/auth/login/", payload);
  } catch (e) {
    return null;
  }
};
