<template>
  <v-container
    fill-height
    fluid
    class="d-flex flex-column justify-center align-center"
  >
    <v-container class="login">
      <h1 class="login-header">
        Biometric tablet’s<br />
        <span class="golden-text">Admin panel</span>
      </h1>
      <v-form v-model="valid" @submit.prevent="login">
        <v-text-field
          v-model="username"
          :rules="rules.username"
          label="Email"
          required
          outlined
          :disabled="!!$route.query.secret"
        ></v-text-field>
        <v-text-field
          v-model="password"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          :type="showPassword ? 'text' : 'password'"
          :rules="rules.password"
          label="Password"
          :disabled="!!$route.query.secret"
          required
          outlined
        ></v-text-field>
        <v-btn
          type="submit"
          :disabled="!valid"
          class="rounded-lg auth-btn"
          width="100%"
          outlined
          large
          >Войти
        </v-btn>
      </v-form>
      <v-container class="created-by">
        <p></p>
        <img
          class="logo-svg"
          src="@/assets/svg/biometric-logo.svg"
          alt="logo"
        />
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import { loginApi } from "@/http/login";
import { getCsrf } from "@/http/csrf";
export default {
  name: "Login",
  data: () => {
    return {
      valid: false,
      username: "",
      password: "",
      showPassword: false,
      rules: {
        username: [(v) => v.length > 0 || "Поле обязательно для заполнения"],
        password: [
          (v) => {
            if (v) {
              return (
                v.length >= 8 || "Длина пароля не может быть меньше 8 символов"
              );
            }
            return "Поле обязательно для заполнения";
          },
        ],
      },
    };
  },
  methods: {
    async login() {
      await getCsrf();
      const response = await loginApi(this.username, this.password);
      if (response) {
        this.$store.commit("saveApiKey", response.data.api_key);
        await this.$router.push({ name: "home" });
      } else {
        this.$root.$emit(
          "showCustomError",
          "Вы ввели неверный логин или пароль"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  width: 400px;
}

.login-header {
  margin-bottom: 32px;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  font-family: "Gilroy-Regular", sans-serif;
  color: #0b3954;

  .golden-text {
    font-weight: 700;
    line-height: 43px;
    color: #b19a13;
  }
}

.auth-btn {
  margin-top: 25px;
  font-family: "Gilroy-Regular", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 1px;

  color: #ffffff;
  text-transform: none;
  background: #3474d3;

  filter: drop-shadow(0px 2px 10px rgba(52, 116, 211, 0.3));
}

.auth-btn:disabled {
  background: #d6dfea;
  border-radius: 8px;
  filter: none;
}

.created-by {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-svg {
    width: 335px;
    height: 60px;
  }
}
</style>
