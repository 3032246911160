import { instance } from "@/plugins/axios";

export const getTransactions = async (limit, offset, fromDate, toDate) => {
  return await instance.get("/cabinet/transactions/", {
    params: {
      limit: limit,
      offset: offset,
      from_date: fromDate,
      to_date: toDate,
    },
  });
};

export const getTransactionBySession = async (session) => {
  return await instance.get(`/cabinet/transactions/${session}/`);
};

export const getStatistics = async (organizationId) => {
  return await instance.get(
    `/organization/${organizationId}/transactions/statistics/`
  );
};
