<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-7">
        <p class="title">Мониторинг</p>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="statistics"
      :loading="loading"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
    >
    </v-data-table>
  </v-container>
</template>

<script>
import { getStatistics } from "@/http/transactions";
import { getOrganizationInfo } from "@/http/organization";
export default {
  name: "Statistics",
  data: () => {
    return {
      statistics: [],
      loading: true,
      headers: [
        {
          text: "Технология",
          align: "center",
          sortable: false,
          value: "technology",
        },
        {
          text: "Количество оставшихся транзакций",
          align: "center",
          sortable: true,
          value: "left_quantity",
        },
        {
          text: "Количество использованных транзакций",
          align: "center",
          sortable: true,
          value: "used_quantity",
        },
        {
          text: "Общее количество транзакций",
          align: "center",
          sortable: true,
          value: "total_quantity",
        },
      ],
    };
  },
  methods: {
    async getOrganizationInfo() {
      const organizationInfo = await getOrganizationInfo();
      this.$store.commit("saveOrganizationId", organizationInfo.data.id);
    },
    async getStatistics() {
      const statistics = await getStatistics(this.$store.state.organization_id);
      console.log(statistics);
      this.statistics = statistics.data.statistics;
      console.log(this.statistics);
      this.loading = false;
    },
  },
  async mounted() {
    await this.getOrganizationInfo();
    await this.getStatistics();
  },
};
</script>

<style scoped></style>
