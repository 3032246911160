<template>
  <div
    class="left-drawer"
    @mouseenter="toggleMainWidth('enter')"
    @mouseleave="toggleMainWidth('leave')"
  >
    <div class="list-biometric">
      <div class="list-item-biometric">
        <div class="list-item-icon">
          <div class="biometric" />
        </div>
      </div>
    </div>
    <div class="list">
      <div
        v-for="item in items"
        :key="item.id"
        :class="
          item.id === activeID || $route.name === item.route
            ? 'list-item-active'
            : 'list-item'
        "
        @click="changeRoute(item.route, item.id)"
      >
        <div class="list-item-icon">
          <v-img class="item-logo" :src="getImage(item, item.id)" />
        </div>
        <div class="item-name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftNavigationDrawer",
  data() {
    return {
      activeID: -1,
      mini: true,
      drawer: true,
      items: [
        {
          id: 0,
          image: "reports-logo",
          name: "Статистика",
          route: "/statistics",
        },
        {
          id: 1,
          image: "devices-logo",
          name: "Мониторинг",
          route: "/monitoring",
        },
        {
          id: 2,
          image: "roles-logo",
          name: "Виджет",
          route: "/widget",
        },
      ],
    };
  },
  methods: {
    toggleMainWidth(event) {
      this.$emit("toggleMainWidth", event);
    },
    changeRoute(route, index) {
      this.activeID = index;
      this.$router.push(route);
    },
    getImage(item, index) {
      if (index === this.activeID || this.$route.name === item.route)
        return require(`@/assets/svg/${item.image}-active.svg`);
      return require(`@/assets/svg/${item.image}.svg`);
    },
  },
  watch: {
    $route(to) {
      this.items.map((i) => {
        if (i.route.replace("/", "") === to.name) {
          this.activeID = i.id;
        }
      });
    },
  },
  created() {
    this.items.map((i) => {
      if (i.route.replace("/", "") === this.$route.name) {
        this.activeID = i.id;
      }
    });
  },
};
</script>

<style lang="scss">
.left-drawer {
  padding-top: 26px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 108px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #0b3954 !important;
  transition: all 0.2s ease-in-out;

  .list,
  .list-biometric {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .list-item,
  .list-item-biometric,
  .list-item-active {
    width: 220px;
    padding-left: 30px;
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .list-item-icon {
      margin-right: 10px;
    }

    .biometric {
      width: 35px;
      height: 40px;
      margin-bottom: 60px;
      background-image: url("~@/assets/svg/biometric-logo-mini.svg");
    }

    .item-name {
      visibility: hidden;
      font-family: "Gilroy-Medium", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #d6dfea;
    }

    .item-logo {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
  }
}
.left-drawer:hover {
  width: 240px;

  .biometric {
    width: 156px;
    height: 40px;
    margin-bottom: 60px;
    background-image: url("~@/assets/svg/biometric-logo.svg");
  }
  .item-name {
    visibility: visible;
    cursor: pointer;
  }
  .list-item:hover {
    background-color: rgba(249, 249, 249, 0.15);
    box-shadow: 0 4px 20px rgba(62, 61, 87, 0.2);
    border-radius: 10px;
    border-left: 9px solid #f1d742;
  }
  .list-item-active {
    .item-name {
      color: #f1d742;
    }
  }
}
</style>
