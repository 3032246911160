<template>
  <v-container fluid class="px-5">
    <v-row class="options" align="center">
      <v-col cols="auto" class="pl-7">
        <p class="title">Мониторинг</p>
      </v-col>
      <div style="width: 110px">
        <v-col cols="12">
          <div class="input-wrapper">
            <v-select
              @input="getSessions(1)"
              :items="pagination.limits"
              v-model="pagination.limit"
              hide-spin-buttons
              hide-selected
              hide-details
              outlined
            />
          </div>
        </v-col>
      </div>
    </v-row>
    <v-row class="options" align="center">
      <v-row class="flex pl-7 pt-5" style="width: 458px">
        <v-col cols="auto">
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <label>Дата начала </label>
              <div class="input-wrapper" style="width: 200px">
                <v-text-field
                  v-model="params.from_date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </div>
            </template>
            <v-date-picker
              v-model="params.from_date"
              scrollable
              @input="
                menuFrom = false;
                getSessions(1);
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="pl-16">
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <label>Дата конца </label>
              <div class="input-wrapper" style="width: 200px">
                <v-text-field
                  v-model="params.to_date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </div>
            </template>
            <v-date-picker
              v-model="params.to_date"
              scrollable
              @input="
                menuTo = false;
                getSessions(1);
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="sessions"
      :items-per-page="pagination.limit"
      :loading="loading"
      loading-text="Пожалуйста подождите, данные загружаются..."
      hide-default-footer
      @dblclick:row="handleClick"
    >
      <template v-slot:item.result="{ item }">
        <span :class="item.result === false ? 'red--text' : 'green--text'">
          {{ item.result ? "Пройдено" : "Не пройдено" }}
        </span>
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.date.replace("T", " ") }}
      </template>
    </v-data-table>
    <custom-pagination
      @updatePage="getSessions($event)"
      :pagination="pagination"
    />
    <MonitoringInfo
      v-show="modal"
      :dialog="modal"
      ref="prepaid"
      @closeDialog="modal = false"
    />
  </v-container>
</template>

<script>
import MonitoringInfo from "@/components/Monitoring/MonitoringInfo.vue";
import CustomPagination from "@/components/CustomPagination.vue";
import { getTransactions } from "@/http/transactions";

export default {
  name: "Monitoring",
  components: { CustomPagination, MonitoringInfo },
  data: () => {
    return {
      sessions: [],
      loading: true,
      menuFrom: false,
      menuTo: false,
      modal: false,
      headers: [
        {
          text: "Сессия",
          align: "center",
          sortable: true,
          value: "main_session_id",
        },
        {
          text: "Результат",
          align: "center",
          sortable: true,
          value: "result",
        },
        {
          text: "Технологии",
          align: "center",
          sortable: false,
          value: "technologies",
        },
        {
          text: "Дата прохождения",
          align: "center",
          sortable: true,
          value: "date",
        },
      ],
      pagination: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 200,
        page: 1,
        pages: 10,
      },
      params: {
        from_date: new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .substr(0, 10),
        to_date: new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .slice(0, 10),
      },
    };
  },
  methods: {
    handleClick(row, item) {
      this.row = item.item.main_session_id;
      this.modal = !this.modal;
      this.$refs.prepaid.getSessionInfo(this.row);
    },
    getUrl(mediaLink) {
      const url = process.env.VUE_APP_BACKEND_URL.replace("/v1", "");
      return `${url}${mediaLink}`;
    },
    async getSessions(page = 1) {
      this.loading = true;
      this.sessions = [];
      this.pagination.page = page;
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit;
      try {
        const response = await getTransactions(
          this.pagination.limit,
          this.pagination.offset,
          this.params.from_date,
          this.params.to_date
        );
        this.sessions = response.data.results;
        this.loading = false;
        this.pagination.count = response.data.count;
        this.pagination.pages =
          Math.floor(this.pagination.count / this.pagination.limit) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },
  },
  created() {
    this.getSessions();
  },
};
</script>

<style scoped></style>
