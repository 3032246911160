var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800","persistent":""},model:{value:(this.dialog),callback:function ($$v) {_vm.$set(this, "dialog", $$v)},expression:"this.dialog"}},[_c('v-card',{staticClass:"rounded-lg pa-2",staticStyle:{"overflow-x":"hidden"}},[_c('v-card-title',{staticClass:"pb-5"},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"px-0",attrs:{"cols":"auto"}},[_c('p',{staticClass:"dialog-title"},[_vm._v("Сессия")]),_c('p',[_vm._v(_vm._s(this.id))])]),_c('v-col',{staticClass:"px-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"size":"30","color":"error"}},[_vm._v("mdi-close-thick")])],1)],1)],1)],1),_c('v-card-text',{staticStyle:{"padding":"15px 40px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.session,"loading":_vm.loading,"loading-text":"Пожалуйста подождите, данные загружаются...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('img',{staticClass:"pa-5 image",attrs:{"src":item.photo,"alt":"Фотография лайвнесс"}}):_c('p',[_vm._v("Фотография не найдена")])]}},{key:"item.face_photo",fn:function(ref){
var item = ref.item;
return [(item.face_photo)?_c('img',{staticClass:"pa-5 image",attrs:{"src":item.face_photo,"alt":"Фотография с документа"}}):_c('p',[_vm._v("Фотография не найдена")])]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.result === false ? 'red--text' : 'green--text'},[_vm._v(" "+_vm._s(item.result ? "Пройдено" : "Не пройдено")+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date.replace("T", ""))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }