import { instance } from "@/plugins/axios";

export const getWidget = async () => {
  try {
    return await instance.get("/organization/configs/widget/");
  } catch (e) {
    return null;
  }
};

export const updateWidget = async (
  isActive,
  customWidgetLogo,
  documentVerificationSide,
  videoRecording,
  showFullResult
) => {
  const payload = {
    is_active: isActive,
    custom_widget_logo: customWidgetLogo,
    document_verification_side: documentVerificationSide,
    video_recording: videoRecording,
    show_full_result: showFullResult,
  };
  try {
    return await instance.put("/organization/configs/widget/update/", payload);
  } catch (e) {
    return null;
  }
};
